import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
export default {
    theme: WORK_OS_IRIS_COLOR_NAME,
    tabs: [
        {
            tabLabel: "Main table",
            image: {
                src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/view/Table.png"
            },
            asset: {
                image: {
                    src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/view/Maintable_2.png",
                    alt: "Main table view"
                }
            }
        },
        {
            tabLabel: "Gantt",
            image: {
                src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/view/Gantt.png"
            },
            asset: {
                image: {
                    src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/view/Gantt_2.png",
                    alt: "Gantt view"
                }
            }
        },
        {
            tabLabel: "Kanban",
            image: {
                src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/view/Kanban.png"
            },
            asset: {
                image: {
                    src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/view/Kanban_2.png",
                    alt: "Kanban view"
                }
            }
        },
        {
            tabLabel: "Calendar",
            image: {
                src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/view/Calendar.png"
            },
            asset: {
                image: {
                    src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/view/calender_2.png",
                    alt: "Calendar view"
                }
            }
        },
        {
            tabLabel: "Dashboard",
            image: {
                src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/view/Dashboard.png"
            },
            asset: {
                image: {
                    src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/view/Dashboard_2.png",
                    alt: "Dashboard view"
                }
            }
        }
    ]
};
