const TEMPLATE_NAMES = {
    OnePagerAssetTemplate: "OnePagerAssetTemplate",
    OnePagerWithImageTemplate: "OnePagerWithImageTemplate",
    MobileOnePagerWithImageTemplate: "MobileOnePagerWithImageTemplate",
    OnePagerWithVideoTemplate: "OnePagerWithVideoTemplate",
    CompetitorTemplate: "CompetitorTemplate",
    LongTemplate: "LongTemplate",
    SegmentsTemplate: "SegmentsTemplate",
    FixedLongLpTemplate: "FixedLongLpTemplate",
    ArticleTemplate: "ArticleTemplate",
    DirectoryImagesTemplate: "DirectoryImagesTemplate",
    MultipleDirectoryImagesTemplate: "MultipleDirectoryImagesTemplate",
    DynamicTemplate: "DynamicTemplate",
    MobileDynamicTemplate: "MobileDynamicTemplate",
    DevelopersCenterTemplate: "DevelopersCenterTemplate",
    WelcomeNewJoinersTemplate: "WelcomeNewJoinersTemplate",
    RemoteWorkTemplate: "RemoteWorkTemplate"
};
const isFixedLpTemplate = (template)=>{
    return template === TEMPLATE_NAMES.FixedLongLpTemplate || template === TEMPLATE_NAMES.DynamicTemplate;
};
module.exports = {
    TEMPLATE_NAMES,
    isFixedLpTemplate
};
