import * as Sentry from "@sentry/nextjs";
export const fireHotjarEventScript = (eventName)=>`(function(w){
        if(!hj) return;
        w.hj("event","${eventName}");
    })(window)`;
export const fireHotjarEvent = (eventName)=>{
    try {
        if (window.hj) {
            window.hj("event", eventName);
        }
    } catch (e) {
        Sentry.captureException(e);
    }
};
// fires a list of events to Hotjar
export const fireHotjarEvents = (eventNames)=>{
    // have only unique values
    const uniqueEventNames = eventNames.filter((element, index, array)=>array.indexOf(element) === index);
    // fire each event, so filtering can be fully customized on Hotjar side
    uniqueEventNames.forEach((uniqueEventName)=>fireHotjarEvent(uniqueEventName));
};
export const currentSmallerThanFinalDate = (hotjarFinalDate)=>{
    const currentDate = new Date();
    const finalDate = new Date(hotjarFinalDate);
    return currentDate.getTime() <= finalDate.getTime();
};
