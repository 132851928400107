import { getGridImageWithParagraphProps } from "../live-board-tabs-service";
const firstRow = [
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/views/dashboard_views.png",
        fullParagraphTitle: "Dashboard"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/views/gantt_views.png",
        fullParagraphTitle: "Gantt"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/views/kanbann_views.png",
        fullParagraphTitle: "Kanban"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/views/gallary_views.png",
        fullParagraphTitle: "Files Gallery"
    })
];
const secondRow = [
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/views/calendar_views.png",
        fullParagraphTitle: "Calendar"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/views/workload_views.png",
        fullParagraphTitle: "Workload"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/views/Timeline_views.png",
        fullParagraphTitle: "Timeline"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/views/map_views.png",
        fullParagraphTitle: "Map"
    })
];
const thirdRow = [
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/views/form_views.png",
        fullParagraphTitle: "Form"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/views/cards_views.png",
        fullParagraphTitle: "Cards"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/views/chart_views.png",
        fullParagraphTitle: "Chart"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/views/Files_views.png",
        fullParagraphTitle: "Files"
    })
];
export const viewsGridItems = [
    firstRow,
    secondRow,
    thirdRow
];
