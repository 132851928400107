import styled from "@emotion/styled";
export const StyledLiveBoardTabComponent = styled.div`
  display: flex;
  justify-content: center;
  width: 82vw;

  .tab-content {
    padding-bottom: 200px;
    width: 95%;
    cursor: default;

    .description-wrapper {
      text-align: center;
      padding-bottom: 16px;
    }

    .responsive-hoc-component {
      .desktop-wrapper {
        .flexible-cards-grid-desktop-component {
          .flexible-cards-grid-desktop-component-inner {
            .grid-paragraph-with-image-component-wrapper {
              .top-layer {
                .grid-paragraph-with-image {
                  cursor: pointer;

                  &:not(:hover) {
                    box-shadow: unset;
                    border: 1px solid #dcdfec;
                    border-radius: 8px;
                  }

                  &:hover {
                    border: unset;
                    border-radius: 8px;
                    border: 1px solid #dcdfec;
                  }

                  .grid-image-wrapper {
                    .grid-image {
                      .picture-component {
                        img {
                          border-bottom-right-radius: unset;
                          border-bottom-left-radius: unset;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.hide-paragraph {
    .responsive-hoc-component {
      .desktop-wrapper {
        .flexible-cards-grid-desktop-component {
          .flexible-cards-grid-desktop-component-inner {
            .grid-paragraph-with-image-component-wrapper {
              .top-layer {
                .grid-paragraph-with-image {
                  .grid-paragraph-wrapper {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
