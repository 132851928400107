import styled from "@emotion/styled";
import { ALWAYS_ON_TOP } from "constants/z-index";
export const StyledScrollToTop = styled.div`
  opacity: ${({ isDisplayed })=>isDisplayed ? 1 : 0};
  transition: transform 0.3s ease-in-out;
  position: fixed;
  cursor: ${({ isDisplayed })=>isDisplayed ? 'pointer' : 'unset'};
  z-index: ${ALWAYS_ON_TOP};
  right: 36px;
  bottom: 36px;

  svg {
    height: 60px;
    width: 60px;
  }
`;
