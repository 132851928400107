export const TOP_VIDEO_PROPS = {
    video: {
        src: {
            singleUrl: "https://dapulse-res.cloudinary.com/video/upload/Generator_featured%20images/live-board-2022/dashboards/Dashboars_Reports_2022.mp4"
        },
        autoPlay: true,
        playWhenVisible: false,
        loop: true
    }
};
