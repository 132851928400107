import styled from "@emotion/styled";
import colors from "styles/colors";
import { WHITE_COLOR_NAME } from "styles/color-consts";
export const StyledStickyHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0;
    cursor: pointer;
    background-color: ${colors[WHITE_COLOR_NAME]};
    overflow: hidden;
    transition: width 0.5s ease, border-radius 0.5s ease;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);

    .circle-text {
      opacity: 0;
      transition: opacity 0.5s ease;
      white-space: nowrap;
    }

    .picture-component {
      display: flex;
      height: 24px;
      img {
        margin-left: 12px;
        margin-right: 10px;
        transition: margin 0.5s ease;
      }
    }
  }

  .expanded-circle {
    width: 160px;
    height: 50px;
    border-radius: 50px;
    background-color: ${colors[WHITE_COLOR_NAME]};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0;
    cursor: pointer;
    overflow: hidden;
    transition: width 0.5s ease, border-radius 0.5s ease;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);

    .circle-text {
      display: flex;
      opacity: 1;

      padding-right: 10px;
      justify-content: center;
      flex: 1;
    }

    .picture-component {
      display: flex;
      height: 24px;
      img {
        margin-left: 20px;
      }
    }
  }

  .circle:not(.expanded-circle) {
    .circle-text {
      opacity: 0;
    }

    img {
      margin-left: 12px;
    }
  }
`;
