import styled from "@emotion/styled";
export const StyledTabsComponent = styled.div`
  display: flex;
  flex-direction: column;

  &.left {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
  }

  &.right {
    align-items: flex-end;
  }

  .tabs {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .content {
    padding-top: 48px;
  }
`;
