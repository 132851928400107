import styled from "@emotion/styled";
const LINE_WIDTH = 4;
const LINE_WIDTH_STRING = `${LINE_WIDTH}px`;
export const StyledTabComponent = styled.div`
  position: relative;
  text-align: center;
  min-width: 240px;
  cursor: pointer;
  padding: 16px 24px;

  :hover {
    outline: 0;
    background-color: var(--tab-background-color);

    .tab-label {
      color: var(--tab-color);
    }
  }

  &.active {
    background-color: var(--tab-background-color);

    .tab-underline-placeholder {
      background: var(--tab-background-color);

      .tab-underline {
        left: 0;
        right: 0;
      }
    }
  }

  &.small-labels {
    min-width: 160px;

    .tab-label {
      font-size: 0.875rem;
    }
  }

  &.half-screen-tabs {
    min-width: 100px;
    display: flex;
    justify-content: center;
    padding: 8px 5px;

    .title-wrapper {
      .title-and-icon-wrapper {
        .core-title-container .core-title {
          font-weight: 300;
        }

        .icon-wrapper {
          .picture-icon-wrapper {
            height: 20px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }

  .tab-label {
    font-weight: 600;
    font-size: 1.375rem;
  }

  .tab-underline-placeholder {
    position: absolute;
    height: ${LINE_WIDTH_STRING};
    bottom: 0;
    left: 0;
    right: 0;
    border-top-left-radius: ${LINE_WIDTH_STRING};
    border-top-right-radius: ${LINE_WIDTH_STRING};

    .tab-underline {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 50%;
      transition: left 0.2s ease, right 0.2s ease;
      border-top-left-radius: ${LINE_WIDTH_STRING};
      border-top-right-radius: ${LINE_WIDTH_STRING};
    }
  }
`;
