import { getGridImageWithParagraphProps } from "../live-board-tabs-service";
const firstRow = [
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/gmail.png",
        fullParagraphTitle: "integrations - gmail"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/slack.png",
        fullParagraphTitle: "integrations - slack"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/zoom.png",
        fullParagraphTitle: "integrations - zoom"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/zendesk.png",
        fullParagraphTitle: "integrations - zendesk"
    })
];
const secondRow = [
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/hubspot.png",
        fullParagraphTitle: "integrations - hubspot"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/jira.png",
        fullParagraphTitle: "integrations - jira"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/google_drive.png",
        fullParagraphTitle: "integrations - google drive"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/microsoft_teams.png",
        fullParagraphTitle: "integrations - microsoft teams"
    })
];
const thirdRow = [
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/github.png",
        fullParagraphTitle: "integrations - github"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/outlook.png",
        fullParagraphTitle: "integrations - outlook"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/paypal.png",
        fullParagraphTitle: "integrations - paypal"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/dropbox.png",
        fullParagraphTitle: "integrations - dropbox"
    })
];
const fourthRow = [
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/twiio.png",
        fullParagraphTitle: "integrations - twilio"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/mailchimp.png",
        fullParagraphTitle: "integrations - mailchimp"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/integrations/survey_monkey.png",
        fullParagraphTitle: "integrations - survey monkey"
    }),
    getGridImageWithParagraphProps({
        imageSrc: "https://dapulse-res.cloudinary.com/image/upload/200.png",
        fullParagraphTitle: "integrations - 200+"
    })
];
export const integrationsGridItems = [
    firstRow,
    secondRow,
    thirdRow,
    fourthRow
];
