export const circlesData = [
    {
        id: "promise",
        label: "stickyHeader.Promise",
        image: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/menu/Promise_1.png"
    },
    {
        id: "platform",
        label: "stickyHeader.Platform",
        image: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/menu/Platform_1.png"
    },
    {
        id: "products",
        label: "stickyHeader.Products",
        image: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/menu/Products_1.png"
    },
    {
        id: "enterprise",
        label: "stickyHeader.Enterprise",
        image: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/menu/Enterprise_1.png"
    },
    {
        id: "customers",
        label: "stickyHeader.Customers",
        image: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Homepage%20-%202024/menu/Customers_1.png"
    }
];
