import styled from "@emotion/styled";
import Colors from "/styles/colors";
import { BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01, DARK_WORK_OS_IRIS_COLOR_NAME, WHITE_COLOR_NAME } from "styles/color-consts";
import { BANNER_LEFT_VW_MARGIN, BANNER_RIGHT_VW_MARGIN, BANNER_VW_WIDTH, LIVE_BOARD_VW_WIDTH, RIGHT_SECTION_RIGHT_VW_MARGIN, RIGHT_SECTION_VW_WIDTH } from "./live-board-consts";
import { ALWAYS_ON_TOP, LAYER_ONE } from "constants/z-index";
import { LARGE_SCREEN_MIN_WIDTH, MACBOOK_PRO_MAX_WIDTH, MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH, X_LARGE_SCREEN_MIN_WIDTH } from "constants/vp-sizes";
export const StyledLiveBoardScreenComponent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .live-board-open-button-wrapper {
    display: flex;
    align-items: center;

    .live-board-open-button-content {
      z-index: ${LAYER_ONE};
      padding-left: 4px;
      @media (max-width: ${LARGE_SCREEN_MIN_WIDTH}) {
        top: 70vh;
      }

      .x-button-wrapper {
        display: none;
      }

      &:hover {
        .x-button-wrapper {
          display: unset;
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .live-board-close {
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;
    z-index: ${ALWAYS_ON_TOP};
  }

  .close-icon {
    width: 28px;
    height: 22px;

    path {
      stroke: ${Colors[WHITE_COLOR_NAME]};
    }
  }

  .live-board-content-wrapper {
    display: flex;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: ${LIVE_BOARD_VW_WIDTH + "vw"};
    height: 98%;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
    background: ${Colors[WHITE_COLOR_NAME]};
    pointer-events: auto;

    .right-section {
      width: ${RIGHT_SECTION_VW_WIDTH + "vw"};
      margin-right: ${RIGHT_SECTION_RIGHT_VW_MARGIN + "vw"};
      padding-left: 1vw;

      .board-title-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 44px;
        padding-left: 30px;

        .title-content {
          display: flex;
          flex-direction: row;
          gap: 28px;
          align-items: baseline;

          .tag {
            padding: 0 10px;
            margin: 0 2px;
            font-weight: 400;
            font-size: 1rem;
            line-height: 154%;
            border-radius: 2px;
            width: fit-content;
            color: ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
            background-color: ${Colors[BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01]};
          }
        }
      }

      .board-views-tab-component {
        padding-top: 20px;
        padding-left: 20px;
        height: 100%;

        .tabs-component-wrapper {
          height: 100%;

          .tabs-component {
            height: 100%;

            .tabs {
              border-bottom: 1px solid #c3c6d4;
              margin-left: 20px;
              justify-content: left;
              gap: 32px;

              .tab-component {
                .title-wrapper {
                  .title-and-icon-wrapper {
                    .icon-wrapper {
                      margin-right: 8px;

                      .picture-icon-wrapper {
                        height: 25px;
                      }
                    }
                  }
                }

                &.active {
                  .tab-underline-placeholder {
                    background: #0073ea;
                    height: 2px;
                  }
                }
              }
            }

            @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) {
              .tabs {
                gap: 16px;

                .tab-component {
                  .title-wrapper {
                    .title-and-icon-wrapper {
                      .monday-markup-language-component {
                        font-size: 1rem;
                        @media (max-width: ${MACBOOK_PRO_MAX_WIDTH}) {
                          font-size: 0.9rem;
                        }
                      }
                    }
                  }
                }
              }
            }

            .content {
              height: 75%;
              padding-top: 32px;

              .asset-wrapper {
                opacity: unset;
                min-height: unset;
                margin: 16px 30px;

                .asset-inner {
                  cursor: pointer;

                  .video-component {
                    .react-player-video-component {
                      video {
                        border-radius: 12px;
                      }
                    }
                  }
                }
              }

              @media (min-width: ${X_LARGE_SCREEN_MIN_WIDTH}) {
                .asset-wrapper {
                  margin: 16px 60px;
                }
              }

              .tab-component {
                height: 100%;
                padding: unset;
                overflow: scroll;

                :hover {
                  background-color: unset;
                }

                .embedded-board {
                  .tooltip-wrapper {
                    justify-content: left;
                    bottom: unset;
                    top: 230px;
                    margin-left: 10px;

                    .tooltip {
                      &:after {
                        bottom: 40px;
                        transform: rotate(180deg);
                      }
                    }
                  }

                  .embedded-wrapper {
                    border: unset;
                  }
                }

                &.scroll-visible {
                  overflow: visible;
                }
              }
            }
          }
        }
      }

      .banner-wrapper {
        position: fixed;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.15s;
        z-index: ${ALWAYS_ON_TOP};
        bottom: 2vh;
        margin-right: ${BANNER_RIGHT_VW_MARGIN + "vw"};
        margin-left: ${BANNER_LEFT_VW_MARGIN + "vw"};

        .shake {
          .banner {
            width: ${BANNER_VW_WIDTH + "vw"};
            border-radius: 8px;

            .banner-content {
              .banner-text-and-cta {
                .banner-text-and-link {
                  flex-direction: row;

                  .banner-cta-wrapper {
                    .button {
                      .signup-button {
                        .error-handling-component {
                          .shake {
                            .signup-form-core-component-wrapper {
                              .signup-form {
                                .signup-button-wrapper {
                                  .ladda-button {
                                    color: ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.board-fully-visible {
    .live-board-content-wrapper {
      .right-section {
        .banner-wrapper {
          opacity: 1;
        }
      }
    }
  }
`;
