import { useEffect, useRef } from "react";
export default function useInterval(callback, delay, deps = []) {
    const intervalRef = useRef(null);
    const savedCallback = useRef(callback);
    useEffect(()=>{
        savedCallback.current = callback;
    }, [
        callback
    ]);
    useEffect(()=>{
        if (delay === null || delay === 0) {
            if (intervalRef.current) {
                window.clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
            return;
        }
        const tick = ()=>savedCallback.current();
        if (typeof delay === "number") {
            intervalRef.current = window.setInterval(tick, delay);
            return ()=>window.clearInterval(intervalRef.current);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        ...deps,
        delay
    ]);
    return intervalRef;
}
